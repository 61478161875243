import request from '../utils/request'
import baseURL from '../utils/config'

//消息设置-查询数据列表
export function newGetList(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Tip/GetList',
        method: 'get',
        params:data
    })
}

//消息设置-设置是否启用
export function SetEnable(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Tip/SetEnable',
      method: 'get',
      params:data
  })
}
//消息设置-得到详情
export function GetDetail(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Tip/GetDetail',
      method: 'get',
      params:data
  })
}
//消息设置-得到接收者列表
export function GetReceiverRoleList(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Tip/GetReceiverRoleList',
      method: 'get',
      params:data
  })
}
//消息设置-设置是否启用
export function setEnable(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Tip/SetEnable',
      method: 'get',
      params:data
  })
}
//消息设置-新增或者编辑
export function AddOrEdit(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Tip/AddOrEdit',
      method: 'post',
      data
  })
}