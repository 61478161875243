<template>
  <div class="newset-contienr">
    <div class="newset-conter">
      <el-form :model="form" :rules="rules" ref="form" class="demo-form-inline">
        <el-form-item label="业务名称 :">
          <el-input
            style="width: 200px"
            v-model="form.name"
            placeholder="审批人"
            size="small"
            :readonly="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务节点 :">
          <el-input
            style="width: 200px"
            v-model="form.nodeName"
            placeholder="订单支付成功"
            size="small"
            :readonly="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="触发条件 :">
          <span>{{ form.triggerCondition }}</span>
        </el-form-item>
        <el-form-item label="发送者 :" prop="form">
          <el-radio-group v-model="form.senderType">
            <el-radio :label="1">平台</el-radio>
            <el-radio :label="2">商家</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="接收者 :" prop="form">
          <el-radio-group v-model="form.receiverType">
            <el-radio :label="1">平台</el-radio>
            <el-radio :label="2">商家</el-radio>
            <el-radio :label="3">用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="form.receiverType==1" label="接收者角色 :">
          <el-checkbox-group v-model="ppp">
            <el-checkbox v-for="item in role" :key="item.id" :label="item.id">{{
              item.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="内容 :" prop="content">
          <el-input
            style="width: 400px"
            type="textarea"
            maxlength="200"
            v-model="form.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="发送渠道 :" prop="arr2">
          <el-checkbox-group v-model="arr2">
            <el-checkbox :label="1">站内信</el-checkbox>
            <el-checkbox :label="2">短信</el-checkbox>
            <el-checkbox :label="3">APP</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="是否启用 :">
          <el-switch
            v-model="form.enable"
            @change="changeStatus($event)"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div class="newset-end">
        <el-button @click="qx()">取消</el-button>
        <el-button type="primary" @click="bc()">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetDetail,
  GetReceiverRoleList,
  setEnable,
  AddOrEdit,
} from "../../api/newsn";
export default {
  data() {
    const checkChinese = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择"));
      } else {
        callback();
      }
    };

    return {
      ppp: [],
      form: {
        name: "",
        nodeName: "",
        senderType: "",
        receiverType: [],
        content: "",
        resource: [],
        enable: "",
      },
      value: true,
      role: [], //接收者角色列表,
      rolestatus: 1,

      id: "",
      enable2: "", //是否启用
      desc: "", //消息内容
      arr2: [],
      rules: {
        content: [{ required: true, message: "请填写内容", trigger: "blur" }],
        resource: [{ required: true, message: "请选择", trigger: "change" }],
        arr2: [{ validator: checkChinese, trigger: "blur", required: true }],
      },
    };
  },
  methods: {
    // onSubmit() {
    //   console.log('submit!');
    // }
    //获取接收者角色列表
    nGetReceiverRoleList() {
      let data = {
        receiverType: this.form.receiverType,
      };
      GetReceiverRoleList(data)
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.role = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //是否启用
    changeStatus(val) {
      console.log(val);
      this.enable2 = val;
      let data = {
        id: this.id,
        enable: val,
      };
      setEnable(data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑保存
    bc() {
      if(this.form.receiverType==2){
            this.ppp=[]
      }else if(this.form.receiverType==3){
        this.ppp=[]
      }
      let data = {
        tipId: Number(this.id),
        enable: this.form.enable,
        message: this.form.content,
        name: this.form.name,
        nodeId: this.form.nodeId,
        nodeName: this.form.name,
        receiverType: this.form.receiverType,
        senderType: this.form.senderType,
        sendWayTypeList: this.arr2,
        receiverRole: this.ppp,
      };
      AddOrEdit(data)
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.$message("保存成功");
            this.$router.push({ path: "/newsn" });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取消
    qx() {
      this.$router.push({ path: "/newsn" });
    },
  },
  watch: {
    // "form.receiverType"(newn, old) {
    //   let data = {
    //     receiverType: newn,
    //   };
    //   GetReceiverRoleList(data)
    //     .then((res) => {
    //       // console.log(res)
    //       if (res.code === 10000) {
    //         this.role = res.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // arr(newsn, oldsn) {
    //   console.log(newsn, oldsn);
    // },
  },
  created() {
    this.nGetReceiverRoleList();
    this.id = this.$route.query.id;
    let data = {
      id: this.id,
    };
    GetDetail(data).then((res) => {
      console.log(res);
      if (res.code === 10000) {
        (this.form.name = res.data.name),
          (this.form.nodeName = res.data.nodeName),
          (this.form.senderType = res.data.senderType),
          (this.form.receiverType = res.data.receiverType),
          (this.form.enable = res.data.enable),
          (this.form.content = res.data.message),
          (this.arr2 = res.data.sendWayTypeList);
             (this.form.triggerCondition = res.data.triggerCondition),
        this.ppp = res.data.receiverRole;
      }
    });
  },
};
</script>

<style scoped>
.newset-contienr {
  width: 100%;
  height: 105%;
  background: white;
}
.newset-conter {
  width: 613px;
  height: 400px;
  /* background: pink; */
  margin-left: 150px;
}
/deep/ .el-input__inner {
  color: #c4c4c4;
}
</style>
